.table {
    table-layout: fixed;
    min-width: 1000px;
}

.cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.cellHeader {
    border: none !important;
}
